;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"d0XTTByVZmy7e3IpGldGi"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { init } from "@pl/cfg-sentry/sentry.client.config";

init({
  dsn: "https://ac564937863f87d55c1a3e591b29776c@o496444.ingest.us.sentry.io/4508554363142144",
  tunnel:
    "https://hooli.paymentlabs.io?tethics_key=ac564937863f87d55c1a3e591b29776c&project_id=4508554363142144",
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  enableReplayIntegration: true,
  enableCaptureConsoleIntegration: true,
  enableHttpClientIntegration: true,
});
