import cx from "classnames";
import { ToastRoot } from "@pl/app-component-lib";

export type AppContainerProps = {
  children: React.ReactNode;
  fullHeight?: boolean;
  // In general this should always be true, but we generally don't want it to be
  // rendered in tests where it'll introduce an additional status role which
  // will require more assertions to ignore. A bit of a hack but better than a
  // process.env gate since tests can still toggle as necessary.
  withToastRoot?: boolean;
};

export function AppContainer({
  children,
  fullHeight,
  withToastRoot = true,
}: AppContainerProps) {
  return (
    <>
      {withToastRoot && <ToastRoot />}
      <div
        className={cx("flex", {
          "min-h-screen": fullHeight,
        })}
      >
        {children}
      </div>
    </>
  );
}
