import { create } from "zustand";

export type PaymentLabsApp =
  | "gateway"
  | "payee"
  | "payor"
  | "merchant"
  | "checkout"
  | "backoffice"
  | "buyer";

const getPaymentLabsAppList = (): PaymentLabsApp[] => {
  return [
    "gateway",
    "payee",
    "payor",
    "merchant",
    "checkout",
    "backoffice",
    "buyer",
  ];
};

/**
 * e.g /payee/payments -> /payments
 *
 * @param route
 * @returns
 */
export function removeAppPrefixFromRoute(route: string) {
  /* eslint-disable-next-line no-useless-escape */
  const regex = new RegExp(`^\/(${getPaymentLabsAppList().join("|")})\/`);
  return route.replace(regex, "/");
}

type EnvStoreData = {
  app: PaymentLabsApp;
  apiBaseurl: string;
};

type EnvStore = EnvStoreData & {
  update: (data: Partial<EnvStoreData>) => void;
  reset: () => void;
};

const initialStoreData = {
  app: undefined,
  apiBaseurl: undefined,
} as unknown as EnvStoreData;

export const useEnvStore = create<EnvStore>((set, get) => ({
  ...initialStoreData,
  update: (data) => {
    set(() => ({ ...data }));
  },
  reset: () => set({ ...get(), ...initialStoreData }),
}));
