import { useMemo } from "react";
import { useRouter as useNextNavigationRouter } from "next/navigation";
import { useRouter as useNextRouter } from "next/router";
import { logError, useEnvStore } from "@pl/app-services";
import { BuyerRouterModel } from "./models/BuyerRouterModel";
import { CheckoutRouterModel } from "./models/CheckoutRouterModel";
import { GatewayRouterModel } from "./models/GatewayRouterModel";
import { MerchantRouterModel } from "./models/MerchantRouterModel";
import { PayeeRouterModel } from "./models/PayeeRouterModel";
import { PayorRouterModel } from "./models/PayorRouterModel";

export function usePaymentLabsRouter() {
  const { app } = useEnvStore.getState();
  // Need to grab push from next/navigation's useRouter because that one is
  // stable, but the one from next/router's useRouter isn't.
  // https://github.com/vercel/next.js/issues/18127#issuecomment-1619721701
  const { push } = useNextNavigationRouter();
  const { basePath, pathname } = useNextRouter();

  return useMemo(() => {
    if (app === "gateway") {
      return new GatewayRouterModel(basePath, pathname, push);
    } else if (app === "payor") {
      return new PayorRouterModel(basePath, pathname, push);
    } else if (app === "payee") {
      return new PayeeRouterModel(basePath, pathname, push);
    } else if (app === "checkout") {
      return new CheckoutRouterModel(basePath, pathname, push);
    } else if (app === "merchant") {
      return new MerchantRouterModel(basePath, pathname, push);
    } else if (app === "buyer") {
      return new BuyerRouterModel(basePath, pathname, push);
    } else {
      if (!app || app !== "backoffice") {
        logError({
          message: `Unable to match app ${app} to a router model, falling back to gateway model`,
        });
      }
      return new GatewayRouterModel(basePath, pathname, push);
    }
  }, [basePath, pathname, app, push]);
}

export function useGatewayRouter() {
  return usePaymentLabsRouter() as GatewayRouterModel;
}

export function usePayeeRouter() {
  return usePaymentLabsRouter() as PayeeRouterModel;
}

export function usePayorRouter() {
  return usePaymentLabsRouter() as PayorRouterModel;
}

export function useCheckoutRouter() {
  return usePaymentLabsRouter() as CheckoutRouterModel;
}

export function useMerchantRouter() {
  return usePaymentLabsRouter() as MerchantRouterModel;
}

export function useBuyerRouter() {
  return usePaymentLabsRouter() as BuyerRouterModel;
}
