import {
  AccountType,
  ActiveAccount,
  useAuthStore,
} from "#app-services/stores/useAuthStore";
import {
  getItemFromSessionStorage,
  setItemInSessionStorage,
} from "#app-services/utils/sessionStorage";
import { getLocalStorageItem, setLocalStorageItem } from "../localStorage";
import { logFatal } from "../logger";

const ACTIVE_ACCOUNT_KEY = "pl-active-account";
const PL_AUTH_TOKEN_SESSION_STORAGE_KEY = "pl_auth";
export const REDIRECT_PATH_ROUTER_KEY = "pl_redirectPath";

const TokenRoles = ["2fa", "user", "guest", "backoffice", "admin"] as const;

/*
 * The JWT has an account namespace for each type of account/app. Each of these
 * exists as a top level key (payees, payors, etc).
 *
 * Each of those namespaces are mapped to a string which encodes a series of
 * claim pairs that conform to the following schema:
 *
 * - 1 pair: `string:string`
 * - > 1 pairs: `string:string;string:string`
 *
 * The pairs differ by namespace:
 *
 * - Payor & Merchant: `<id>:<claim>`
 * - Payee & Buyer: `<id>:<id>`
 */
type TokenObject = {
  email: string;
  email_verified: boolean;
  given_name: string;
  family_name: string;
  role: (typeof TokenRoles)[number];
  externalId: string;
  payees?: string;
  payors?: string;
  merchants?: string;
  buyers?: string;
  sub: string;
  iat: number;
  iss: string;
  aud: string;
  exp: number;
};

/*
 * In (at least) Chrome, the "Allow all cookies" in "Privacy and security -
 * Cookies and other site data" must be checked in order for an iframe of a
 * different origin than the parent to access sessionStorage (when in
 * Incognito).
 *
 * https://stackoverflow.com/a/26671889
 * https://github.com/auth0/auth0-spa-js/issues/593
 *
 * There are a couple possible workarounds:
 * - Keep the iframe on the same origin as the parent. This isn't really
 *   feasible for various reasons.
 * - Keep the data in memory.
 *
 * We're doing the second option, with sessionStorage as a fallback. Doing it
 * this way will work for any iframe-embedded apps (in memory), and also for
 * non-iframe contexts - benefit being, non-iframe contexts can benefit from
 * token persistence while the session is active.
 */
export function getAuthToken(inMemoryOnly?: boolean) {
  const tokenFromStore = useAuthStore.getState().token;
  if (typeof tokenFromStore === "string" || inMemoryOnly) {
    return tokenFromStore;
  }

  return getItemFromSessionStorage(PL_AUTH_TOKEN_SESSION_STORAGE_KEY);
}

export function setAuthToken(token: string) {
  useAuthStore.getState().update({ token });
  setItemInSessionStorage(PL_AUTH_TOKEN_SESSION_STORAGE_KEY, token);
}

export function getTokenObject() {
  const token = getAuthToken();

  if (!token) {
    return null;
  }

  return decodeJwt(token);
}

export function getTokenState(): "invalid" | "expired" | "valid" {
  const token = getAuthToken();
  if (!token) {
    // if token not exists, we handle it as need a new login
    return "expired";
  }

  const decodedToken = decodeJwt(token);

  if (!decodedToken) {
    return "invalid";
  }

  if (decodedToken.exp && new Date() > new Date(decodedToken.exp * 1000)) {
    return "expired";
  } else if (
    !TokenRoles.includes(decodedToken.role) ||
    decodedToken.role === "2fa"
  ) {
    return "invalid";
  }

  return "valid";
}

export function reset() {
  useAuthStore.getState().reset();
  expireToken();
}

export function updateActiveAccount(payorId: string, type: AccountType) {
  const account = {
    payorId,
    type,
  };

  setLocalStorageItem(ACTIVE_ACCOUNT_KEY, JSON.stringify(account));
  useAuthStore.getState().update({
    activeAccount: account,
  });

  return account;
}

export function getActiveAccount(
  fatalIfMissing?: boolean
): ActiveAccount | undefined {
  const activeAccount =
    useAuthStore.getState().activeAccount ||
    JSON.parse(getLocalStorageItem(ACTIVE_ACCOUNT_KEY) || "{}");

  if (activeAccount && Object.keys(activeAccount).length > 0) {
    return activeAccount;
  } else if (fatalIfMissing) {
    logFatal({
      message:
        "getActiveAccount invoked but no active account found, should not be possible",
    });
  }
}

/*
 * Syncs the active account across all session stores (sessionStorage, and the
 * in memory auth store).
 *
 * Use cases:
 *
 * - Fresh load of app, has token only in sessionStorage. Syncs it into the auth
 *   store so it exists in runtime memory.
 * - Load the payee app in embedded mode, which takes an active account via
 *   query string config. This account can be different than an account that may
 *   already exist in sessionStorage. This is the inverse of the above case,
 *   overwriting the sessionStorage value with the new active account.
 */
export function syncActiveAccount() {
  const activeAccount = getActiveAccount();

  if (activeAccount) {
    return updateActiveAccount(activeAccount.payorId, activeAccount.type);
  }
}

function expireToken(): void {
  setItemInSessionStorage(PL_AUTH_TOKEN_SESSION_STORAGE_KEY, "");
  useAuthStore.getState().update({ token: undefined });
}

/*
 * Reflection
 */

export function isActiveAccountPayorOwnerOrApprover(): boolean {
  const token = getTokenObject();
  if (!token) {
    return false;
  }

  const activeAccount = getActiveAccount();
  if (!activeAccount || activeAccount.type !== "payor") {
    return false;
  }
  return generateAccountClaimPairMap(token).payors.some(
    (p) =>
      p.id === activeAccount.payorId &&
      (p.claim === "owner" || p.claim === "approver")
  );
}

export function doesTokenHaveRole(role: (typeof TokenRoles)[number]): boolean {
  return getTokenObject()?.role === role;
}

/*
 * Utils
 */

function decodeJwt(encoded: string): TokenObject | null {
  try {
    const jwt = encoded.split(".");
    return JSON.parse(atob(jwt[1]));
  } catch (e) {
    logFatal({ message: "Unexpected JWT parse error", exception: e as Error });
    return null;
  }
}

type TokenAccountNamespace = "payees" | "payors" | "merchants" | "buyers";
type PayorClaims = "user" | "owner" | "approver";
type MerchantClaims = PayorClaims;
type PayeeClaims = string;
type BuyerClaims = string;

type ClaimPairMapEntry<T> = {
  id: string;
  claim: T;
};
type TokenAccountClaimPairMap = {
  payees: ClaimPairMapEntry<PayeeClaims>[];
  payors: ClaimPairMapEntry<PayorClaims>[];
  buyers: ClaimPairMapEntry<BuyerClaims>[];
  merchants: ClaimPairMapEntry<MerchantClaims>[];
};

function generateAccountClaimPairMap(
  token: TokenObject
): TokenAccountClaimPairMap {
  return {
    payees: parseClaimPairs<PayeeClaims>("payees", token),
    payors: parseClaimPairs<PayorClaims>("payors", token),
    merchants: parseClaimPairs<MerchantClaims>("merchants", token),
    buyers: parseClaimPairs<BuyerClaims>("buyers", token),
  };
}

function parseClaimPairs<T>(
  namespace: TokenAccountNamespace,
  token: TokenObject
): ClaimPairMapEntry<T>[] {
  const claimPairs = token[namespace];

  if (!claimPairs) {
    return [];
  }

  return claimPairs.split(";").map((pair) => {
    const [id, claim] = pair.split(":");
    return { id, claim: claim as T };
  }, [] as ClaimPairMapEntry<T>[]);
}
