import { BaseRouterModel } from "./BaseRouterModel";

const RouteMap = {
  batch: (batchId: string) => `/batches/${batchId}`,
  batchImport: (batchId: string, importId: string) =>
    `/batches/${batchId}/imports/${importId}`,
  batchImports: (batchId: string) => `/batches/${batchId}/imports/all`,
  allBatches: () => "/batches/all",
  allPayments: () => "/payments/all",
  statements: () => "/ledger/statements",
  transactionHistory: () => "/ledger/transaction-history",
  settings: () => "/settings",
};

export class PayorRouterModel extends BaseRouterModel {
  constructor(
    basePath: string,
    pathname: string,
    push: (pathname: string) => void
  ) {
    super(basePath, pathname, push);
  }

  getRouteMap() {
    return {
      ...super.getRouteMap(),
      ...RouteMap,
    };
  }

  /*
   * Route Group Reflection
   */

  isRestrictedRoute(pathname?: string): boolean {
    return (
      super.isRestrictedRoute(pathname) ||
      [
        RouteMap.batch("[batchId]"),
        RouteMap.batchImport("[batchId]", "[importId]"),
        RouteMap.batchImports("[batchId]"),
        RouteMap.allBatches(),
        RouteMap.allPayments(),
        RouteMap.statements(),
        RouteMap.transactionHistory(),
        RouteMap.settings(),
      ].includes(pathname || this.pathname)
    );
  }

  /*
   * Restricted Routes - goTo
   */

  goToBatch(id: string) {
    this.goTo(RouteMap.batch(id));
  }

  goToBatchImport(batchId: string, importId: string) {
    this.goTo(RouteMap.batchImport(batchId, importId));
  }

  goToBatchImports(batchId: string) {
    this.goTo(RouteMap.batchImports(batchId));
  }

  goToAllBatches() {
    this.goTo(RouteMap.allBatches());
  }

  goToAllPayments() {
    this.goTo(RouteMap.allPayments());
  }

  goToStatements() {
    this.goTo(RouteMap.statements());
  }
  goToTransactionHistory() {
    this.goTo(RouteMap.transactionHistory());
  }

  goToSettings() {
    this.goTo(RouteMap.settings());
  }

  /*
   * Restricted Routes - Reflection
   */

  isCurrentRouteSettings() {
    return this.pathname === RouteMap.settings();
  }

  /*
   * Misc
   */

  getImportFileTemplateLink(type: "csv" | "xlsx") {
    return `${this.basePath || ""}/template.${type}`;
  }
}
