import cx from "classnames";
import { RenderStateSection } from "@pl/app-component-lib";
import { Main } from "#app-common/modules/app-shell/Main";

export type MainLoadingGateProps = {
  fullHeight?: boolean;
};

export function MainLoadingGate({ fullHeight }: MainLoadingGateProps) {
  return (
    <Main className={cx("items-center", { "h-full": fullHeight })}>
      <RenderStateSection variant="loading" height="xl" />
    </Main>
  );
}
