import { isClientSide } from "#app-services/utils/browser";
import { logInfo } from "./logger";

export function getItemFromSessionStorage(key: string) {
  if (!isClientSide) {
    return null;
  }

  let item = null;
  try {
    item = sessionStorage.getItem(key);
  } catch (error) {
    // Logging for info purposes to tie issues to incognito mode or other edge
    // cases. Not critical because there's nothing to be done - sessionStorage
    // may or may not exist, nothing we can do whether the failure is expected
    // or not.
    logInfo({
      message: "Failed to get item from sessionStorage",
      data: {
        error,
        key,
      },
    });
    return null;
  }

  return item;
}

export function setItemInSessionStorage(key: string, item: string) {
  if (!isClientSide) {
    return false;
  }

  try {
    sessionStorage.setItem(key, item);
  } catch (error) {
    // See above comment
    logInfo({
      message: "Failed to set item in sessionStorage",
      data: {
        error,
        key,
      },
    });
    return false;
  }

  return true;
}
